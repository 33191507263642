/*! Accordion 1.1.1
 * ==========================
 * @desc - Simple accordion
 * @requires - jQuery 1.7+ | The associated css (accordion.css)
 * @notes - 
 */  
(function ($) {
    $.fn.extend({
        accordion: function () {

            console.log("accordion!")
            function setActive(self, $el) {
                var hash = $el.attr('href');
                var elm = $(hash);
                var inProp = $(".pf-form").length > 0;
                var set = $(self).attr('data-accordionset');

                if (elm.length > 0) {
                    console.log($(self).children())
                        $(self).children(".accordion-item").children(".accordion-item-open").removeClass("accordion-item-open");

                    elm.addClass('accordion-item-open');
                }


            }

            function setActiveViaHash(self, hash) {
                var elm = $(hash);

                if (elm.length > 0) {
                    $(self).find(".accordion-item-open").removeClass("accordion-item-open");                  
                    elm.addClass('accordion-item-open');
                }
            }


            return this.each(function () {
                var self = this;
                
                //location hash
                var thishash = location.hash;

                if (thishash.length > 0) {
                    if(thishash.endsWith("-noscroll")){
                        console.log(thishash);
                        thishash = thishash.replace("-noscroll","");
                        console.log(thishash);
                    }

                    setActiveViaHash(self, thishash);
                } else{
                    //default accordion-active - no longer needed as done via css
                    // $(".accordion-trigger").trigger("click");
                    console.log("set active");
                    console.log($(this).find('.accordion-item:first > .accordion-trigger'))
                    var firstActive = ($(this).find('.accordion-item-open > .accordion-trigger').length > 0) ? $(".noaction") : $(this).find('.accordion-item:first > .accordion-trigger');
                    firstActive.trigger("click");
                }

                //triggers
                $(this).children(".accordion-item").children("a.accordion-trigger[href^='#']").on("click", function(e) {
                    // console.log('loggy log log');
                    e.preventDefault();
                    if(!$(this).parent().hasClass("accordion-item-open")){
                        setActive(self, $(this));
                        return false;    
                    } else{
                        $(this).parent().removeClass("accordion-item-open");
                    }
                });

                //default accordion-active - no longer needed as done via css
                



                // if ("onhashchange" in window) {
                //     window.onhashchange = setActiveViaHash(self, location.hash);
                // }

            });
        }
    });
})(jQuery);


//by default, initialise on elements with the class of .tabbed...
$(document).ready(function () {
    $('.accordion').accordion();
    console.log("ACCORDION GO");
});