/*!
 *  Stunnplate script.js
 *  Version: 1.3.2
 *
 */
 /* 
 *  
 *  Notes:
 *  Main JS file to include entire library followed by custom scripts.
 *
 * 
 */

/*-------------------------------------------------------*/
/*- SITE SPECIFIC SCRIPT                                -*/
/*-------------------------------------------------------*/

//This is no longer the place for page specific stuff. make sure you use _pagespecific.js for that! (040117 MG)


/********************************************************************/

var width = window.innerWidth,
    height = window.innerHeight,
    sectionPage = $('#section-page'),
    sliderArrow = '<svg viewBox="0 0 25 25"><use xlink:href="/img/icons.svg#icon-left-arrow"></use></svg>';

$(function() {

    // Check if it is iOS
	var isiOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false);
	if(isiOS === true) {
		var tempCSS = $('a').css('-webkit-tap-highlight-color');
		$('body').css('cursor', 'pointer')
				 .css('-webkit-tap-highlight-color', 'rgba(0, 0, 0, 0)');
		$('a').css('-webkit-tap-highlight-color', tempCSS);
    }

    $(".main-nav-mnu-item-has-children > a").on("click", function (e) {
        e.preventDefault();
        
        var _this = $(this),
            list = _this.closest(".main-nav-mnu-list"),
            item = _this.parent(),
            items = list.find(".main-nav-mnu-item-has-children"),
            listToggle = item.find(".main-nav-mnu-submenu"),
            otherContent = list.find(".main-nav-mnu-submenu"),
            duration = 500;

        if (!item.hasClass("open")) {

            items.removeClass("open");
            item.addClass("open");
            otherContent.stop(true, true).slideUp(duration);
            listToggle.stop(true, true).slideDown(duration);

        } else {

            listToggle.stop(true, true).slideUp(duration);
            item.removeClass("open");

        }

    });

    $(".main-mmu-btn").on("click", function(e) {
        e.preventDefault();

        $(".main-nav").addClass("open");
        $("#home").addClass("main-mmu-open");
        $(".main-mmu-btn").addClass("hide");
        if ( sectionPage.length ) {
            //fullpage_api.setAllowScrolling(false);
        }

    });

    $(".main-nav-close").on("click", function(e) {
        e.preventDefault();

        $(".main-nav").removeClass("open");
        $("#home").removeClass("main-mmu-open");
        $(".main-mmu-btn").removeClass("hide");

        fullpage_api.destroy();
        setTimeout(function() {
            fullPage();
        }, 500);
        //fullpage_api.reBuild();

        /*if ( sectionPage.length ) {
            fullpage_api.setAllowScrolling(true);
        }*/
        /*setTimeout(function() {
            $("body").click();
        }, 700);*/

    });

    var mainSlider = $(".section-slider");
    mainSlider.owlCarousel({
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        loop: true,
        items: 1,
        margin: 200,
        nav: false,
        navText: [],
        mouseDrag: false,
        autoplay: true,
        autoplayTimeout: 850000,
        dots: false,
    });

    mainSlider.on('changed.owl.carousel', function (event) {

        mainSlider.trigger('stop.owl.autoplay');

        $(".section-slider-item-bg").removeClass("scale");
        setTimeout(function() {
            $(".section-slider-item-bg").addClass("scale");
            mainSlider.trigger('play.owl.autoplay');
        }, 100);

    });
    $(".slider-btn-left").on('click', function (event) {
        mainSlider.trigger('prev.owl.carousel');
    });
    $(".slider-btn-right").on('click', function (event) {
        mainSlider.trigger('next.owl.carousel');
    });

    $(".slider-item-info").hover(function() {
        
        if ( !$(this).hasClass("not-hover") ) {

            var info = $(".section-slider .owl-item.active .section-slider-item-info"),
                src = info.data("img-url"),
                title = info.find(".section-slider-item-info-title").text(),
                desc = info.find(".section-slider-item-info-desc").text(),
                container = $(".slider-item-info-container"),
                cImg = container.find(".slider-item-info-logo img"),
                cTitle = container.find(".slider-item-info-title"),
                cDesc = container.find(".slider-item-info-desc");

            mainSlider.trigger('stop.owl.autoplay');

            cImg.attr("src", src);
            cTitle.text(title);
            cDesc.text(desc);

            var containerHeight = container.outerHeight(),
                navHeight = $(".slider-nav").outerHeight();
            
            container
                .css("top", -containerHeight/2 + navHeight/2 )
                .addClass("open");

        }

    }, function() {});
    $(".slider-item-info-container-close").on("click", function() {

        mainSlider.trigger('play.owl.autoplay');
        $(".slider-item-info-container").removeClass("open");

    });

    $(".content-print").on("click", function() {
        window.print();
    });

    $(".slider-item-close").on("click", function() {

        var content_height =(-46 - $(this).closest(".branding-slide-info").height()) + 'px';
        // var content_height =(-46 - $(this).closest(".branding-slide-info").height()) + 'px';
        $(this).closest(".branding-slide-info").css('bottom',content_height);
        $(this).closest(".branding-slide-info").css('background-color',"transparent");
        $(this).closest(".branding-slide-info").css('border-top-width',"1px");
        $(this).closest(".branding-slide-info").css('border-top-color',"rgba(151,151,150,0.654);");
        $(this).closest(".branding-slide-info").css('border-top-style',"solid");
        // $(this).closest(".branding-slide-info").css('padding',"41px 75px 0 60px ");
        // $(this).closest(".branding-slide-info").css('height',"auto");
        // $(this).closest(".branding-slide-info").css('border-top-style',"solid");
        $(this).closest(".branding-slide-info").find('.slider-item-close').hide();
        $(this).closest(".branding-slide-info").find('.slider-item-open').show();

    });


    $(".slider-item-open").on("click", function() {
        $(this).closest(".branding-slide-info").css('bottom','0');
        $(this).closest(".branding-slide-info").css('background-color',"#000");
        $(this).closest(".branding-slide-info").css('border',"none");
        $(this).closest(".branding-slide-info").css('height',"auto");

        var x = window.matchMedia("(max-width: 767px)")
        if(x.matches) {
            $(this).closest(".branding-slide-info").css('padding',"55px 25px 30px 25px");
        }
	    else{
            $(this).closest(".branding-slide-info").css('padding',"41px 75px 37px 60px");
        }

        // $(this).closest(".branding-slide-info").css('border-top',"2px solid red;");
        $(this).closest(".branding-slide-info").find('.slider-item-close').show();
        $(this).closest(".branding-slide-info").find('.slider-item-open').hide();

    });

    var content = $(".slider-item-close");
    if(content){
        jQuery.each( content, function( i, val ) {
            var content_height =(-46 - $(val).closest(".branding-slide-info").height()) + 'px';
            $(val).closest(".branding-slide-info").css('bottom',content_height);
        });
    }




    $(".carusel-section-min").owlCarousel({
        loop: true,
        items: 1,
        margin: 5,
        nav: true,
        navText: [sliderArrow, sliderArrow],
        dots: false,
    });

    //Update actions for forms (could be in any pane) 
    setFormActions()
    
    formingHrefTel();
    fullPage();


});


function setFormActions(){
    $('form').each(function(){
        let thisAction = $(this).closest('section').attr("data-anchor");

        $(this).attr("action","#" + thisAction);
    });
}

$(document).mouseup(function (e) {
    var mainSlider = $(".section-slider");
    var content = $(".slider-item-info-container");
    if ($(e.target).closest(".wrapp-slider-item-info").length) return;
    content.removeClass("open");
    mainSlider.trigger('play.owl.autoplay');
    e.stopPropagation();
});

$(document).mouseup(function (e) {
    if ($(e.target).closest(".main-nav").length) return;
    $(".main-nav").removeClass("open");
    $("#home").removeClass("main-mmu-open");
    $(".main-mmu-btn").removeClass("hide");
    if ( sectionPage.length ) {
        //fullpage_api.setAllowScrolling(true);
    }
    e.stopPropagation();
});


function animationInit(sectionCurrent, sectionNext) {

    $.each(sectionCurrent.find(".is-animated"), function() {

        var _this = $(this),
            animName = _this.data("amin-name");

        if(animName === undefined) {
            
            animName = "fadeIn";

        }

        _this.removeClass('animated ' + animName).css('animation-delay', "0s");

    });

    $.each(sectionNext.find(".is-animated"), function() {

        var _this = $(this),
            animName = _this.data("amin-name"),
            animDelay = _this.data("anim-delay");

        if(animDelay === undefined) {

            animDelay = "0s";

        }

        if(animName === undefined) {

            animName = "fadeIn";

        }

         _this.addClass('animated ' + animName).css('animation-delay', animDelay);

    });

}

function blogInite(windowWidth) {

    var section = $("#blog");

    if (section.length) {

        var row = section.find(".blog-row"),
            sectionNavList = $(".section-nav-list"),
            sectionNavItemTitle = sectionNavList.find("li").eq(1).text();
            sectionCount = 3,
            itemMas = [],
            itemMasCount = 0;
        
        $.each(row.find(".blog-col"), function (index) {


            itemMas.push($(this));
            itemMasCount++;
            $(this).remove();

        });


        var sectionHtml = '<section class="section section-blog section-bg-white" data-anchor="section-3">' +
            '<div class="container-center section-padding-container container-wrap container-not-flex">' +
            '<div class="blog-row"></div></div></section>';


        if (windowWidth > 1024) {


            $.each(itemMas, function (index) {

                row.append($(this));

            });

        } else if (windowWidth <= 1024 && windowWidth > 667) {



            section.after(sectionHtml).next().addClass("section-gray section-blog-second");

            if ( itemMasCount > 4 ) {
                sectionNavList.append('<li data-menuanchor="section-3"><a href="#section-3"><span>03. section title</span></a></li>');
            }

            $.each(itemMas, function (index) {

                if (index < 4) {

                    row.append($(this));

                } else if (index > 3 && index < 8) {

                    $(".section-blog-second").find(".blog-row").append($(this));

                } else {

                    return false;

                }

            });

        } else {


            sectionHtml = '<section class="section section-blog section-bg-white" data-anchor="section-3">' +
                '<div class="container-center section-padding-container container-wrap container-not-flex">' +
                '<div class="blog-row"></div></div></section>';

            section.after(sectionHtml).next().addClass("section-gray section-blog-second");

            sectionHtml = '<section class="section section-blog section-bg-white" data-anchor="section-4">' +
                '<div class="container-center section-padding-container container-wrap container-not-flex">' +
                '<div class="blog-row"></div></div></section>';

            $(".section-blog-second").after(sectionHtml).next().addClass("section-blog-third");




            $.each(itemMas, function (index) {


                if (index === 0) {

                    row.append($(this));

                } else if (index === 1) {

                    $(".section-blog-second").find(".blog-row").append($(this));
                    sectionCount++;

                } else if (index === 2) {

                    $(".section-blog-third").find(".blog-row").append($(this));
                    sectionCount++;

                }

                else {

                    return false;

                }

            });

        }

    }

}

var mapContactUs;

function swipingBehavior() {


    var mobile = (navigator.userAgent.match(/(Mobile|Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini)/g) ? true : false);

    // var mq = window.matchMedia( "(max-width: 767px)" );
    var mq = window.matchMedia( "(max-width: 900px)" );


    // if ( mobile ) {
    if (mq.matches ) {


        var section = $(".section-swiping");


        if ( section.length ) {

            $.each(section, function() {


                var thisSection = $(this),
                    sectionContainer = thisSection.find(".section-swiping-container"),
                    sectionText = thisSection.find(".section-swiping-text").html(),
                    sectionImg = thisSection.find("div.section-swiping-img").html(),
                    sectionMap = thisSection.find(".section-swiping-map").html(),
                    sectionBlock = thisSection.find(".section-swiping-block"),
                    slideHtml = '<div class="section-swiping-slide"></div>';

                sectionContainer.trigger("destroy.owl.carousel").empty();

                sectionContainer
                    .addClass("owl-carousel owl-theme")
                    .append(slideHtml).append(slideHtml);

                var slide = sectionContainer.find(".section-swiping-slide"),
                    slideWidth = slide.width(),
                    slideHeight = slide.height(),
                    heightFlag = 0;
                
                sectionBlock
                    .append(sectionText)
                    .css("width", slideWidth);
                
                var sectionBlockItem = sectionBlock.find("> *");
                
                $.each(sectionBlockItem, function(index) {


                    var elem = $(this),
                        elemHeight = elem.outerHeight(true);


                    heightFlag += elemHeight;

                    if ( slideHeight > heightFlag ) {

                        slide.append(elem);
                        elem.remove();

                    } else {

                        heightFlag = elemHeight;
                        sectionContainer.append(slideHtml);
                        slide = slide.next();
                        slide.append(elem);
                        elem.remove();
                    }

                });

                if( sectionImg === undefined ) {

                    sectionContainer.find(".section-swiping-slide").last().remove();

                } else {

                    sectionContainer.find(".section-swiping-slide").last().append(sectionImg);

                }

                if( sectionMap != undefined ) {

                    // sectionContainer.find(".section-swiping-slide").first().append(sectionMap);

                    if(!mapContactUs){
                        mapContactUs=thisSection.find("#map3");
                    }
                    console.log('Aquiiii')
                    // let mapContactUs = thisSection.find("#mapContactUs");


                        console.log(mapContactUs);
                        let currentSlide = sectionContainer.find(".section-swiping-slide");
                        currentSlide.addClass('section-swiping-slide-map');

                        currentSlide.first().append(mapContactUs);


                }
                
                sectionContainer.owlCarousel({
                    loop: false,
                    items: 1,
                    margin: 0,
                    nav: false,
                    dots: false
                });
                

            });

        }
    }
    else{
        if(!mapContactUs){
            mapContactUs=$("#map3");
        }

        var wrapMap3 =$('#wrapMap3');
        wrapMap3.append(mapContactUs);


    }

}

function mobileSwitches(){
    if($(window).outerWidth() > 1024){
        $(".section-contact-mobile").removeClass("section");
        // fullpage_api.reBuild();
    } else{
        $(".section-contact-mobile").addClass("section");
        // fullpage_api.reBuild();
    }
}

// $(window).on("resize",function(){
//     mobileSwitches();
//     fullpage_api.destroy();
//         setTimeout(function() {
//             fullPage();
//         }, 500);
// });

function fullPage() {

    if ( sectionPage.length ) {

        blogInite(width);
        swipingBehavior();
        mobileSwitches();

        $(window).on("resize", function() {
            swipingBehavior();
        });
        
        fullPageInite();

    }

}

function fullPageInite() {

    var header = $(".header"),
        sectionNav = $(".section-nav"),
        btnSectionDown = $(".btn-section-down"),
        mainSlider = $(".section-slider"),
        scrollB = false,
        scrollOv = true,
        // speed = 700;
        speed = 1300;

    sectionPage.fullpage({
        licenseKey: "B368DF60-EAE94E7D-9C55EB1E-8516952E",
        controlArrows: true,
        verticalCentered: false,
        lockAnchors: true,
        scrollingSpeed: speed,
        scrollBar: scrollB,
        scrollOverflow: scrollOv,
        fixedElements: '.main-nav',
        normalScrollElements: '.main-nav',
        menu: '#section-nav-list',
        onLeave: function (origin, destination, direction) {

            var sectionOrigin = $(".section[data-anchor='" + origin.anchor + "']"),
                sectionDestination = $(".section[data-anchor='" + destination.anchor + "']");

            sectionOrigin.addClass("section-border");

            setTimeout(function () {
                sectionDestination.removeClass("section-border");
            }, 400);

            if (!sectionDestination.hasClass("section-nav-white")) {

                header.addClass("header-section-white");
                sectionNav.addClass("section-nav-section-white");

            } else {

                header.removeClass("header-section-white");
                sectionNav.removeClass("section-nav-section-white");

            }
            

            if (sectionDestination.hasClass("section-logo-gray")) {

                header.addClass("header-section-logo-white");

            } else {

                header.removeClass("header-section-logo-white");

            }

            if (sectionDestination.hasClass("section-mnu-white")) {

                header.addClass("header-section-mnu-white");

            } else {

                header.removeClass("header-section-mnu-white");

            }


            // if (destination.index === 0) {
            //
            //     btnSectionDown.removeClass("none");
            //
            // } else {
            //
            //     btnSectionDown.addClass("none");
            //
            // }

            if ($(".section[data-anchor='" + destination.anchor + "']").hasClass("not-section-nav")) {

                sectionNav.addClass("section-nav-hide");

            } else {

                sectionNav.removeClass("section-nav-hide");

            }

            if (destination.anchor === "footer") {

                header.addClass("header-section-hide-logo");

            } else {

                header.removeClass("header-section-hide-logo");

            }

            if ($(".section[data-anchor='" + destination.anchor + "']").find(".wrapp-section-slider").length) {

                $(".slider-nav").addClass("open");
                mainSlider.trigger('play.owl.autoplay');

                $(".section-slider-item-bg").removeClass("scale");
                setTimeout(function () {
                    $(".section-slider-item-bg").addClass("scale");
                }, speed);

            } else {

                $(".slider-nav").removeClass("open");
                mainSlider.trigger('stop.owl.autoplay');

            }

            animationInit(sectionOrigin, sectionDestination);

        },
        afterRender: function (origin, destination, direction) {

            var sectionOrigin = $(".section[data-anchor='" + this.anchor + "']"),
                sectionDestination = $(".section[data-anchor='" + this.anchor + "']");

            $(".section-slider-item-bg").addClass("scale");

            $(".section").addClass("section-border");
            $(".section[data-anchor='" + this.anchor + "']").removeClass("section-border");

            if ($(".section[data-anchor='" + this.anchor + "']").find(".wrapp-section-slider").length) {

                $(".slider-nav").addClass("open");

            } else {

                $(".slider-nav").removeClass("open");

            }

            $(".section-text-btn").find("a").removeClass("ui-link");

            if (!sectionOrigin.hasClass("section-nav-white")) {

                header.addClass("header-section-white");
                sectionNav.addClass("section-nav-section-white");

            } else {

                header.removeClass("header-section-white");
                sectionNav.removeClass("section-nav-section-white");

            }

            animationInit(sectionOrigin, sectionDestination);

            //fullpage_api

        }
    });

    $(".ui-link").on("click", function (e) {

        var thisId = $(this).attr("href");
        hash = thisId.slice(0, 1);

        if (hash === "#") {

            thisId = thisId.slice(1);
            e.preventDefault();
        }

        fullpage_api.moveTo(thisId);
    });


    $(".section-next-block").on("click", function (e) {

        e.preventDefault();

        fullpage_api.moveSectionDown();

    });

}

function isNumber(n) {

    return !isNaN(parseFloat(n)) && isFinite(n);
    
}

function formingHrefTel() {

	var linkAll = $('.formingHrefTel'),
		joinNumbToStringTel = 'tel:';

	$.each(linkAll, function () {
		var _this = $(this),
			linkValue = _this.text(),
			arrayString = linkValue.split("");

		for (var i = 0; i < arrayString.length; i++) {
			var thisNunb = isNumber(arrayString[i]);
			if (thisNunb === true || (arrayString[i] === "+" && i === 0)) {
				joinNumbToStringTel += arrayString[i];
			}
		}

		_this.attr("href", function () {
			return joinNumbToStringTel;
		});
		joinNumbToStringTel = 'tel:'

	});

}


$(window).load(function(){
    // $(window).on('resize', function(){
    var x = window.matchMedia("(min-width: 1025px)");
    if(x.matches) {
        var margin = ($('.section-add-padding  .section-img-padding-bg-left').height() ) + 'px';
        // $('.flexible-container .col-left.col-padding').css("margin-bottom", margin);
        $('.section-add-padding  .section-swiping-text').css("margin-bottom", margin);
    }
    else{

        $('.section-add-padding .section-swiping-text').css("margin-bottom", 0);
    }
    
    $(".accordion-trigger").on("click",function(){
        fullpage_api.reBuild();
    })


})

