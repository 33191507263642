/*
	Define page specific scripts here which will be loaded by _domreadyutil.js based on the ID and class of the body tag 
	of any page
 */

// The utility converts dashed classnames to camelCase as we can't use dashes in our variables here so resourcesPage = resources-page

PAGESCRIPT = {
  	common : {
    	init     : function(){
			sniffbrowser();

			// This is where you can put GLOBAL dom ready scripts
			
    	},
    	finalize : function() {

    	}
  	},
  	contactPage : {
    	init     : function() {
			// This is an example of where to put PAGE SPECIFIC dom ready scripts. The script automaticall converts to camelCase for example, .contact-page becomes contactPage here
		    
    	}
  	}
};